import React from 'react'
import ReactDOM from 'react-dom'
import TextLoop from 'react-text-loop'

class SignUp extends React.Component {

  render() {
    return <div className="contenedor-login">
      <div className="login">
        <div className="titulo">
          <h1 id="#bienvenida">
            <TextLoop springConfig={{ stiffness: 180, damping: 8 }} >
              <span>Bienvenida</span>
              <span>Bienvenido</span>
              <span>Bienvenide</span>
              <span>Bienvenidx</span>
            </TextLoop>
            {" "}
            a Tablito,
          </h1>
          <h2>inicia sesión con el Backoffice.</h2>
        </div>

        <form action="/auth/backoffice" method="post">
          <input type="hidden" name="authenticity_token" value={document.querySelector('[name=csrf-token]').content}/>
          <button className="boton" type="submit" name="v" value="" >login</button>
        </form>
      </div>
    </div>;
  }
}


document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <SignUp/>,
    document.body.appendChild(document.createElement('div'))
  )
});
